import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router/index'
import * as echarts from "echarts";
//引入element-plus模块
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "echarts-gl"; //3D地图插件
import Highcharts from 'highcharts'
 
import Highcharts3d from 'highcharts/highcharts-3d'
Highcharts3d(Highcharts)
// 引入element
const vueMain = createApp(App)

//使用ElementPlus
// app.use(ElementPlus)
// app.mount('#app')
vueMain.config.globalProperties.$echarts = echarts
vueMain.use(router)
vueMain.use(ElementPlus);
vueMain.mount('#app')
export default vueMain