const routes = [{
        path: '/',
        component: () => import('@/view/index')
    },
    {
        path: '/index',
        component: () => import('@/view/index')
    },
    {
        path: '/organize',
        component: () => import('@/view/organize')
    },
    {
        path: '/news',
        component: () => import('@/view/news')
    },
    {
        path: '/newsDetail',
        component: () => import('@/view/newsDetail')
    },
    {
        path: '/wish',
        component: () => import('@/view/wish')
    },
    {
        path: '/makeWish',
        component: () => import('@/view/makeWish')
    },
    {
        path: '/wishDetail',
        component: () => import('@/view/wishDetail')
    },
    {
        path: '/wishClaim',
        component: () => import('@/view/wishClaim')
    },
    {
        path: '/wishClaim',
        component: () => import('@/view/wishClaim')
    },
    {
        path: '/company',
        component: () => import('@/view/company')
    },
    {
        path: '/companyDetail',
        component: () => import('@/view/companyDetail')
    },
    {
        path: '/suggest',
        component: () => import('@/view/suggest')
    },
    {
        path: '/suggestDetail',
        component: () => import('@/view/suggestDetail')
    },
    {
        path: '/appointment',
        component: () => import('@/view/appointment')
    },
    {
        path: '/appointmentDetail',
        component: () => import('@/view/appointmentDetail')
    },
    {
        path: '/login',
        component: () => import('@/view/login')
    },
    {
        path: '/activity',
        component: () => import('@/view/activity')
    },
    {
        path: '/detail',
        component: () => import('@/view/detail')
    },
    {
        path: '/chart',
        component: () => import('@/view/chart')
    },
    {
        path: '/chartJing',
        component: () => import('@/view/chartJing')
    },
    {
        path: '/chartIndex',
        component: () => import('@/view/chartIndex')
    },
];

export default routes