<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
.highcharts-credits {
  display: none;
}
* {
  margin: 0;
  padding: 0;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.zan {
  text-align: center;
  font-size: 32px;
  color: gray;
  margin: 20px 0 40px;
  width: 100%;
}
.flex {
  display: flex;
}
.align {
  align-items: center;
}
.between {
  /* 2边对齐 */
  justify-content: space-between;
}
.around {
  justify-content: space-around;
}
.absolute {
  position: absolute;
}
.wrap {
  flex-wrap: wrap;
}
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}
.return {
  position: absolute;
  color: #ed1c24;
  font-size: 40px;
  left: 40px;
  top: 46px;
  img {
    width: 48px;
    margin-right: 15px;
  }
}
.pointer {
  cursor: pointer;
}
.el-select--large {
  width: 100%;
}
.el-select .el-input {
  background: none !important;
}
.makeWish .el-select .el-input {
  background: #eeeeee !important;
}
.el-pagination.is-background .btn-next.is-active,
.el-pagination.is-background .btn-prev.is-active,
.el-pagination.is-background .el-pager li.is-active {
  background: linear-gradient(to bottom, #f99e93, #d4201c);
}
.main {
  border: solid 24px #c50807;
  margin: 0 60px;
  border-radius: 10px;
  .main-bg {
    background: rgba(255, 255, 255, 0.8);
    padding: 26px;
  }
}
.titleN {
  color: #d71718;
  font-size: 72px;
  font-weight: bold;
  text-align: center;
  margin: 64px 0 40px;
  letter-spacing: 5px;
}
.input-item {
  .el-input--large,
  .el-textarea__inner {
    font-size: 18px;
    background: #eeeeee;
  }
  .el-input__wrapper:hover {
    box-shadow: none;
    outline: none;
  }
}
.el-input--large .el-input__wrapper {
  box-shadow: none;
  background: none;
}
.pagination {
  justify-content: center;
  width: 100%;
  .total {
    color: #ffb300;
    font-size: 16px;
    margin-left: 60px;
  }
}
.content {
  img {
    max-width: 100%;
    display: block;
    margin: 4px auto;
  }
}
</style>
